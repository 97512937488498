import request from "@/utils/request"

// 合作伙伴列表一览
export function selectArchivesPage(data) {
  return request({
    url: "/archives/selectArchivesPage",
    method: "post",
    data
  })
}

// 根据手机号查询用户类型
export function getArchivesContact(query) {
  return request({
    url: '/archives/getArchivesContact',
    method: 'get',
    params: query
  })
}

// 新增
export function insertArchive(data) {
  return request({
    url: "/archives/insertArchive",
    method: "post",
    data
  })
}

// 编辑
export function updateArchive(data) {
  return request({
    url: "/archives/updateArchive",
    method: "post",
    data
  })
}

// 根据手机号查询用户类型
export function selectById(query) {
  return request({
    url: '/archives/selectById',
    method: 'get',
    params: query
  })
}

//运营档案-内容管理头部详情
export function selectArchivesContent(query) {
  return request({
    url: '/archives/selectArchivesContent',
    method: 'get',
    params: query
  })
}

// 根据企业名称模糊查询
export function selectByCompanyFullName(query) {
  return request({
    url: '/archives/selectByCompanyFullName',
    method: 'get',
    params: query
  })
}

//运营档案-资讯类型数量
export function getArchivesContentNum(query) {
  return request({
    url: '/archives/getArchivesContentNum',
    method: 'get',
    params: query
  })
}

//生成跳转链接
export function generateLink(query) {
  return request({
    url: '/archives/generateLink',
    method: 'get',
    params: query
  })
}

//获取客户回复列表各类总数量
export function getNum(query) {
  return request({
    url: '/archives/getNum',
    method: 'get',
    params: query
  })
}

//运营档案-客户回复顾问信息
export function selectArchivesUser(data) {
  return request({
    url: "/archives/selectArchivesUser",
    method: "post",
    data
  })
}

//运营档案-客户回复专家信息
export function selectArchivesSpecialist(data) {
  return request({
    url: "/archives/selectArchivesSpecialist",
    method: "post",
    data
  })
}


//运营档案-客户回复
export function selectArchivesApiUser(data) {
  return request({
    url: "/archives/selectArchivesApiUser",
    method: "post",
    data
  })
}

// 运营档案-新增专家
export function insertArchivesSpecialist(data) {
  return request({
    url: "/archives/insertArchivesSpecialist",
    method: "post",
    data
  })
}

//运营档案-编辑专家
export function updateArchivesSpecialist(data) {
  return request({
    url: "/archives/updateArchivesSpecialist",
    method: "post",
    data
  })
}

// 运营档案-专家详情
export function selectArchiveSpecialist(query) {
  return request({
    url: '/archives/selectArchiveSpecialist',
    method: 'get',
    params: query
  })
}

// 运营档案-新增顾问
export function insertConsultant(data) {
  return request({
    url: "/archives/insertConsultant",
    method: "post",
    data
  })
}

// 根据字段模糊查询系统用户
export function selectArchivesUserPage(data) {
  return request({
    url: "/archives/selectArchivesUserPage",
    method: "post",
    data
  })
}

// 运营档案-专家冻结/解冻
export function deleteArchivesSpecialist(query) {
  return request({
    url: '/archives/deleteArchivesSpecialist',
    method: 'get',
    params: query
  })
}

// 运营档案-顾问冻结/解冻
export function deleteArchivesUser(query) {
  return request({
    url: '/archives/deleteArchivesUser',
    method: 'get',
    params: query
  })
}

// 运营档案冻结、解冻
export function deleteArchive(query) {
  return request({
    url: '/archives/deleteArchive',
    method: 'get',
    params: query
  })
}

// 编辑企业响应次数、最新运营时间
export function updateArchiveResponseNumAndOperationTime(data) {
  return request({
    url: "/archives/updateArchiveResponseNumAndOperationTime",
    method: "post",
    data
  })
}