<template>
  <div>
    <div class="openClose">
      <div class="openInfo" :class="{ newClass: isShow }">
        <el-radio-group v-model="value" @change="changeVal">
          <el-radio-button label>不限</el-radio-button>
          <el-radio-button
            v-for="(item, index) in options"
            :key="index"
            :label="item.labelName"
          ></el-radio-button>
        </el-radio-group>
      </div>
      <div class="openButton" @click="openItem()" v-if="options.length > 10">
        {{ openSpan }}
        <i :class="openicon"></i>
      </div>
    </div>
    <div class="children" v-if="childrenArray.length > 0">
      <span v-if="value">子标签：</span>
      <el-checkbox-group v-model="checkboxVal" @change="changeText">
        <el-checkbox-button v-for="item in childrenArray" :label="item.labelName" :key="item.id">{{
          item.labelName
        }}</el-checkbox-button>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { getIndustryLabel } from '@/api/common'
import { getChild } from '@/api/content'
export default {
  name: 'DicTagButton',
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: ''
    },
    code: {
      // 选中的那一项的key
      type: String,
      default: '',
      require: true
    },
    queryName: {
      // 需要给后端的字段
      type: String
    },
    name: {
      // 前端展示的字段
      type: String
    },
    tag: {
      type: String
    }
  },
  watch: {
    code: function (newVal) {
      this.value = newVal

      if (!newVal) {
        this.getDicData()

        this.childrenArray = []
      }
    }
  },
  data() {
    return {
      nameList: [],
      openSpan: '展开全部',
      openicon: 'el-icon-arrow-down',
      openValue: true,
      options: [],
      value: '',
      checkboxVal: [],
      childrenArray: [],
      firstVal: '',
      isShow: false
    }
  },
  created() {
    this.getDicData()
  },
  methods: {
    async getDicData() {
      let { data: res } = await getIndustryLabel()
      this.options = res.data
      if (this.code) {
        this.options.forEach((item) => {
          if (item.labelName === this.code) {
            this.value = item
          }
        })
      }
    },
    changeText(val) {
      const arr = [...this.nameList, ...val]
      this.nameList = [...new Set(arr)]
      console.log(this.nameList)
      this.$emit('onChange', this.firstVal, this.queryName, this.name, this.nameList)
    },

    async changeVal(val) {
      this.firstVal = val
      this.childrenArray = []
      this.checkboxVal = []
      this.nameList = [val]
      let parentId = ''
      this.options.forEach((el) => {
        if (el.labelName === val) {
          parentId = el.id
        }
      })
      const { data: res } = await getChild({ parentId })
      if (res.resultCode === 200 && res.data) {
        this.childrenArray = res.data
      } else {
        this.childrenArray = []
        this.nameList = []
      }
      this.$emit('onChange', this.firstVal, this.queryName, this.name, this.nameList)
      this.$emit('update:code', val.labelName)
    },
    // 标签打开
    openItem() {
      if (this.openValue) {
        this.isShow = true
        this.openSpan = '展开全部'
        this.openicon = 'el-icon-arrow-up'
        this.openValue = false
      } else {
        this.isShow = false
        this.openSpan = '展开全部'
        this.openValue = true
        this.openicon = 'el-icon-arrow-down'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.openInfo {
  width: 90%;
  max-height: 48px;
  overflow: hidden;
  // transition: all 1s linear;
}
.newClass {
  width: 90%;
  max-height: 400px;
  overflow: none;
  transition: all 1s linear;
}
/deep/.el-checkbox-group {
  background-color: #f5f6f7;
  box-sizing: border-box;
  padding: 10px 0;
}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-left: none;
  border-radius: 4px;
}
::v-deep .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 4px;
}
::v-deep .el-checkbox-button__inner {
  border: none;
  border-radius: 4px;
}
::v-deep .el-checkbox-button {
  margin-right: 12px;
  margin-bottom: 10px;
}
.children {
  background-color: #f5f6f7;
  display: flex;
}
span {
  color: #606266 !important;
  padding-left: 20px;
  display: inline-block;
  min-width: 60px;
}
.openClose {
  display: flex;
  justify-content: space-between;
  .openButton {
    width: 10%;
    line-height: 48px;
    color: #1e9fff;
    cursor: pointer;
    text-align: center;
  }
}
// /deep/ .el-radio-button__inner,
// .el-radio-group {
//   padding: 5px;
// }
/deep/ .el-radio-group {
  padding: 5px;
  padding-left: 0;
}
</style>
